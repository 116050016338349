<template>
  <div>
    <div class="row">
      <div class="col-md-3">
        <a-input v-model:value="employeeName" placeholder="Поиск по названию" @change="searchEmployee" allow-clear />
      </div>
      <div class="col-md-3">
        <a-select
          v-model:value="storeId"
          :options="stores"
          :option-filter-prop="'label'"
          placeholder="Выберите филиал"
          style="width: 100%"
          @change="searchStore"
          show-search
        />
      </div>
      <div class="text-right col-md-6">
        <a-button
          v-if="user.role === 'admin'"
          type="link"
          @click="router.push({ name: 'employee' })"
        >
          <template #icon><UserSwitchOutlined /></template>
          <span>Список сотрудников</span>
        </a-button>
      </div>
    </div>
    <a-table
      :columns="columns"
      :data-source="dataSource"
      :loading="loading"
      :pagination="false"
      @change="tableChange"
    >
      <template #name="{ record }">
        <div v-if="record.photo_url === '' || record.photo_url == null" class="employee-photo">
          <img width="47" src="https://cdn.multicard.uz/storage/29e/e8576059ab6c74182ab7797992857.png" alt="">
        </div>
        <div
          v-else
          :style="'background-image: url(\'' + record.photo_url + '\')'"
          class="employee-photo"
        ></div>
        <span class="employee-name">
          {{ record.name }}
        </span>
      </template>
      <template #wallet="{ record }">
        <div v-if="record['wallet']" class="w-100 text-center">
          {{ (record['wallet']['type'] === 'personal') ? formattedBalance(record['wallet']['balance']) : '' }}
        </div>
      </template>
      <template #points="{ record }">
        <div class="w-100 text-center">
          {{ record.points }}
        </div>
      </template>
      <template #store="{ record }">
        <span>
          {{ record.store.name }}
        </span>
      </template>
      <template #role="{ record }">
        {{ record.role_name }}
      </template>
      <template #invite_sent="{ record }">
        <a-tooltip
          v-if="record['invite_sent']"
          :title="record['invite_sent']">
          <a-tag color="success">Отправлено</a-tag>
        </a-tooltip>
        <a-tag color="default" v-else>Не отправлено</a-tag>
      </template>
      <template #phone="{ text }">
        {{ formattedPhone(text) }}
      </template>
      <template #date="{ text }">
        <span>
          {{ (new Date(text)).toLocaleDateString() }} {{ (new Date(text)).toLocaleTimeString() }}
        </span>
      </template>
      <template #qr="{ record }">
        <a :href="record['short_link']" target="_blank" style="max-height: 55px; max-width: 55px; display: block">
          <img style="max-width: 100%; max-height: 100%" :src="record['qr']" alt="">
        </a>
      </template>
      <template #action="{ record }">
        <div class="text-right">
          <a-tooltip placement="topRight" class="mx-2">
            <template #title>Восстановить</template>
            <a @click="showRestoreModal(record.id)"><RestOutlined /></a>
          </a-tooltip>
        </div>
      </template>
    </a-table>
    <div class="mt-4 text-right">
      <a-pagination
        show-size-changer
        v-if="total > query['per-page'].value"
        v-model:pageSize="query['per-page'].value"
        v-model:current="query.page.value"
        :total="total"
      />
    </div>
    <a-modal
      v-model:visible="restoreModal"
      @cancel="hideRestoreModal"
      :footer="null"
    >
      <a-typography-title :level="5">Вы действительно хотите вернуть сотрудника?</a-typography-title>
      <div class="footer overflow-hidden pt-2">
        <a-button @click="restoreEmployee" type="primary" class="float-right">Да</a-button>
        <a-button @click="hideRestoreModal" type="secondary" class="float-right mx-2">Нет</a-button>
      </div>
    </a-modal>
  </div>
</template>

<script setup>
import { UserSwitchOutlined, RestOutlined } from "@ant-design/icons-vue";
import {computed, onMounted, reactive, ref, toRefs, watch} from 'vue';
import { useRoute, useRouter } from "vue-router";
import apiClient from "@/services/axios";
import { message } from "ant-design-vue";
import { mask } from 'maska';
import {useStore} from "vuex";

let
  employeeName = ref(''),
  storeId = ref(null),
  dataSource = ref([]),
  restoreModal = ref(false),
  restoreId = ref(0),
  loading = ref(false),
  employeeTimer = null,
  total = ref(0),
  roles = ref([]),
  stores = ref([]);

const router = useRouter(),
  route = useRoute(),
  user = computed(() => useStore().getters['user/user']),
  initialParams = {
    page: 1,
    'per-page': 10,
    sort: '',
    name: '',
  },
  queryParams = reactive({ ...initialParams }),
  columns = [
    {
      title: 'Имя сотрудника',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      slots: {
        customRender: 'name',
      },
    },
    {
      title: 'Филиал',
      dataIndex: 'store_id',
      key: 'store_id',
      sorter: true,
      slots: {
        customRender: 'store',
      },
    },
    {
      title: 'Роль',
      dataIndex: 'role_id',
      sorter: true,
      key: 'role',
      slots: {
        customRender: 'role',
      },
    },
    {
      title: 'Номер телефона',
      dataIndex: 'phone',
      sorter: true,
      key: 'phone',
      slots: {
        customRender: 'phone',
      },
    },
    {
      title: 'Telegram ID',
      dataIndex: 'login',
      key: 'login',
    },
    {
      title: 'Статус отправки СМС',
      dataIndex: 'invite_sent',
      key: 'invite_sent',
      slots: {
        customRender: 'invite_sent',
      },
    },
    {
      title: 'Баланс',
      dataIndex: 'wallet',
      sorter: false,
      key: 'wallet',
      slots: {
        customRender: 'wallet',
      },
    },
    {
      title: 'Рейтинг',
      dataIndex: 'points',
      sorter: true,
      key: 'points',
      slots: {
        customRender: 'points',
      },
    },
    {
      title: 'QR',
      dataIndex: 'qr',
      sorter: false,
      key: 'qr',
      slots: {
        customRender: 'qr',
      },
    },
    {
      key: 'action',
      slots: { customRender: 'action' },
    },
  ],
  updateParams = params => {
    Object.assign(queryParams, params)
  },
  tableChange = (pag, filters, sorter) => {
    if (typeof sorter.order == "string") {
      let sign = ''
      if (sorter.order === 'descend')
        sign = '-'
      updateParams({ sort: sign + sorter.field })
    }
  },
  searchEmployee = (e) => {
    if (e.target.value.length > 0 && e.target.value.length < 3)
      return;
    if (employeeTimer) {
      clearTimeout(employeeTimer);
      employeeTimer = null;
    }
    employeeTimer = setTimeout(() => {
      updateParams({ name: e.target.value })
    }, 800);
  },
  searchStore = (e) => {
    updateParams({ store_id: e })
  },
  showRestoreModal = (id) => {
    restoreModal.value = true
    restoreId.value = id
  },
  hideRestoreModal = () => {
    restoreModal.value = false
    restoreId.value = 0
  },
  restoreEmployee = () => {
    return apiClient.post(`employee/restore?id=${restoreId.value}`).then(response => {
      if (response) {
        message.success('Сотрудник успешно восстановлен')
      }
    }).catch(() => {
      // console.log(err)
    }).finally(() => {
      hideRestoreModal()
      reload()
    });
  },
  formattedPhone = (phone) => {
    return (phone) ? mask(phone, '+998(##) ###-##-##') : ''
  },
  formattedBalance = (balance) => {
    let formatter = new Intl.NumberFormat('RU', {
      style: 'currency',
      currency: 'UZS',
    });
    let formattedBalance = formatter.format(balance / 100)
    return formattedBalance.substring(0, formattedBalance.length - 4)
  },
  getData = (params) => {
    params.archived_only = 1;
    loading.value = true
    return apiClient.get('/employee', {
      params,
    }).then(({data}) => {
      dataSource.value = data.data.items
      total.value = data.data.total
      stores.value = data.data.stores
      roles.value = data.data.roles
      loading.value = false
    }).catch(() => {
      // console.log(err)
    }).finally(() => {
      loading.value = false
    });
  },
  reload = () => {
    getData({
      ...queryParams,
    })
  };

onMounted(() => {
  if (route.query.store_id) {
    storeId.value = route.query.store_id
    updateParams({ store_id: route.query.store_id })
  } else {
    getData({
      ...queryParams,
    })
  }
})

watch(queryParams, () => {
  router.push({
    name: 'employee-archived',
    query: {
      ...route.query,
      ...queryParams,
    },
  })
  getData({
    ...route.query,
    ...queryParams,
  })
})

const query = {...toRefs(queryParams)}

</script>

<style scoped>
@import "./index.css";
</style>
